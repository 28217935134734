@forward 'vuetify/settings' with (

    $color-pack: false, // Remove color pack to optimize build size

    // Override vuetify sass variables
    //$navigation-drawer-transition-duration : 0.6s,
    //$navigation-drawer-transition-timing-function: cubic-bezier(.47,0,.37,1.14),
    $overlay-opacity: 0.1,

    $snackbar-wrapper-margin: 0px,
    $snackbar-wrapper-min-width: 100vw,
    $snackbar-wrapper-max-width: 100vw,
    $snackbar-wrapper-padding: 8px,

    $tabs-height: 70px,

    $button-plain-opacity: 1,
    //$button-icon-font-size: 0.8rem,
    //$button-content-transition: transform background opacity .3s cubic-bezier(0.4, 0, 0.2, 1)

    $chip-plain-opacity: 1,

    $switch-inset-track-height: 18px,
    $switch-inset-track-width: 32px,
    $switch-track-width: 26px,
    $switch-thumb-width: 12px,
    $switch-thumb-height: 12px,
    $switch-thumb-offset: 0px,

    $navigation-drawer-transition-duration: 0.6s,
    $navigation-drawer-transition-timing-function: ease,
);